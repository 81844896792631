<template>
    <v-container fluid>
        <v-row no-gutters class="pt-2">
            <v-col cols="12">
                <v-form class="custom-form" ref="saleForm" @submit.prevent="saveSale">
                    <v-row dense>
                        <v-col cols="12">
                            <v-row dense>
                                <v-col cols="6">
                                    <v-card 
                                        height="100%"
                                        color="white lighten-4"
                                        class="shadow1 pb-2"
                                    >
                                        <v-toolbar color="white lighten-4" :elevation="0" height="40px">
                                            <v-toolbar-title class="subtitle-2">Customer Information</v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text class="py-1 pa-0 pl-2 pr-2">
                                            <v-row>
                                                <v-col cols="11" class="py-0 pa-0 pl-3 pr-4">
                                                    <v-combobox 
                                                        dense
                                                        outlined
                                                        hide-details
                                                        @focus="$store.dispatch('customer/getCustomers')"
                                                        :items="customers"
                                                        :loading="$store.getters['customer/loadingCustomers']"
                                                        item-text="display_text"
                                                        item-value="id"
                                                        v-model="customer"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                                <v-col cols="1" class="py-0 pl-2 pa-0" style="margin-left: -17px;">
                                                    <v-btn to="/customer" target="_blank" fab x-small depressed color="primary">
                                                        <v-icon color="white">mdi-plus</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="6" v-if="customer.id">
                                    <v-card 
                                        color="white lighten-4"
                                        class="shadow1 pb-2"
                                    >
                                        <v-toolbar color="white lighten-4" :elevation="0" height="40px">
                                            <v-toolbar-title class="subtitle-2">
                                                Product Information
                                            </v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text class="py-1 pa-0 pl-2 pr-2">
                                            <v-form ref="productForm" class="custom-form" @submit.prevent="addToCart">
                                                <v-row class="py-2">
                                                    <v-col cols="11" class="py-0 pa-0 pl-3 pr-4">
                                                        <v-combobox 
                                                            dense
                                                            outlined
                                                            hide-details
                                                            @focus="$store.dispatch('product/getProducts')"
                                                            :items="$store.getters['product/products']"
                                                            :loading="$store.getters['product/loadingProducts']"
                                                            item-text="display_text"
                                                            item-value="id"
                                                            v-model="product"
                                                            ref="product"
                                                        >
                                                        </v-combobox>
                                                    </v-col>
                                                    <v-col cols="1" class="py-0 pa-0" style="margin-left: -17px;">
                                                        <v-btn to="/product" target="_blank" fab x-small depressed color="primary">
                                                            <v-icon color="white">mdi-plus</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="6" v-else>
                                    <v-card 
                                        color="white lighten-4"
                                        class="shadow1 pb-6"
                                    >
                                        <v-toolbar color="white lighten-4" :elevation="0" height="40px">
                                            <v-toolbar-title class="subtitle-2">Product Information</v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text class="py-1 pa-0 pl-2 pr-2">
                                            <h2>Select customer first</h2>
                                        </v-card-text>
                                    </v-card>
                                </v-col> 
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-card :elevation="1" class="px-0">
                                        <v-toolbar color="white lighten-4" :elevation="0" height="30px" style="border-bottom:1px solid #ddd;">
                                            <v-toolbar-title class="subtitle-1">Cart Information</v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text class="py-3 px-1">
                                            <v-simple-table dense>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th>SL</th>
                                                            <th>Product Name</th>
                                                            <th>{{ customer.type }} Rate</th>
                                                            <th>Carton</th>
                                                            <th>Pice</th>
                                                            <th>Free Qty</th>
                                                            <th>Total</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, ind) in cart" :key="ind">
                                                            <td class="text-center" style="width:3%">{{ind+1}}</td>
                                                            <td>
                                                                <template v-if="item.product.item_type == 1">
                                                                    <strong>Name:</strong> {{item.name}} <br> 
                                                                    <strong>Stock:</strong> 
                                                                        {{ (item.current_quantity - (item.current_quantity % item.product.carton_quantity)) / item.product.carton_quantity }} {{ item.product.unit.name }}
                                                                        {{ item.current_quantity % item.product.carton_quantity }} Pice 
                                                                </template>
                                                                <template v-else>
                                                                    <strong>Name:</strong> {{item.name}} <br> 
                                                                    <strong>Stock:</strong> 
                                                                    {{ item.current_quantity }}
                                                                </template>
                                                            </td>
                                                            <td class="text-right" style="width:8%">
                                                                <!-- <input class="form_control" type="number" v-model="item.sale_rate"> -->
                                                                {{ getRate(item.product) }}
                                                            </td>
                                                            <td class="text-right" style="width:5%">
                                                                <template v-if="item.product.item_type == 1">
                                                                    <input style="max-width:100px;" class="form_control" type="number" v-model="item.carton">
                                                                </template>
                                                            </td>
                                                            <td class="text-right" style="width:5%">
                                                                <input style="max-width:100px;" class="form_control" type="number" v-model="item.pice">
                                                            </td>
                                                            <td class="text-right" style="width:5%">
                                                                <input style="max-width:100px;" class="form_control" type="number" v-model="item.freeQty">
                                                            </td>
                                                            <td class="text-right" style="width:5%">
                                                                {{ getTotalPrice(item) }}
                                                            </td>
                                                            <td class="text-center" style="width:5%">
                                                                <v-icon small @click="deleteCart(ind)" color="error">mdi-delete-circle-outline</v-icon>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-row dense>
                                <v-col cols="12">
                                    <v-card 
                                        class="shadow1"
                                        color="white lighten-4"
                                    >
                                        <v-toolbar color="white lighten-4" :elevation="0" height="40px">
                                            <v-toolbar-title class="subtitle-2">Invoice Information</v-toolbar-title>
                                        </v-toolbar>
                                        <v-row dense>
                                            <v-col col="6">
                                                <v-card-text class="py-1 pa-0 pl-2 pr-2">
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Invoice</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="sale.invoice"
                                                                readonly
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Date</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="sale.date"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Employee</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-combobox 
                                                                dense
                                                                outlined
                                                                hide-details
                                                                @focus="$store.dispatch('employee/getEmployees')"
                                                                :items="$store.getters['employee/employees']"
                                                                :loading="$store.getters['employee/loadingEmployees']"
                                                                item-text="display_text"
                                                                item-value="id"
                                                                v-model="employee"
                                                            >
                                                            </v-combobox>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">PO No</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="sale.po_no"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">PO Date</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="sale.po_date"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row> 
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Note</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-textarea
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="sale.note"
                                                            ></v-textarea>
                                                        </v-col>
                                                    </v-row> 
                                                </v-card-text>
                                            </v-col>
                                            <v-col col="6">
                                                <v-card-text class="py-1 pa-0 pl-2 pr-2">
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Sub Total</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                type="number"
                                                                min="0"
                                                                readonly
                                                                v-model="sale.sub_total"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Vat</v-col>
                                                        <v-col cols="3" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                type="number"
                                                                min="0"
                                                                v-model.number="vatPercent"
                                                                @input="calculateTotal"
                                                                id="vat"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="1"><span class="ml-1">%</span></v-col>
                                                        <v-col cols="4" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                type="number"
                                                                min="0"
                                                                v-model.number="sale.vat"
                                                                @input="calculateTotal"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Discount</v-col>
                                                        <v-col cols="3" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                type="number"
                                                                min="0"
                                                                v-model.number="disPercent"
                                                                @input="calculateTotal"
                                                                id="discount"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="1" class="py-0"><span class="ml-1">%</span></v-col>
                                                        <v-col cols="4" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                type="number"
                                                                min="0"
                                                                v-model.number="sale.discount"
                                                                @input="calculateTotal"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Transport</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                type="number"
                                                                min="0"
                                                                v-model.number="sale.transport"
                                                                @input="calculateTotal"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Unload</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                type="number"
                                                                min="0"
                                                                v-model.number="sale.unload"
                                                                @input="calculateTotal"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Total</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                type="number"
                                                                min="0"
                                                                v-model.number="sale.total"
                                                                @input="calculateTotal"
                                                                readonly
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Paid</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                type="number"
                                                                min="0"
                                                                v-model.number="sale.paid"
                                                                @input="calculateTotal"
                                                                :readonly="customer.type == 'G' ? true : false"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Due</v-col>
                                                        <v-col cols="4" class="py-0">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                readonly
                                                                v-model.number="sale.due"
                                                                @input="calculateTotal"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="4" class="pl-2">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                readonly
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1"></v-col>
                                                        <v-col cols="4" class="py-1">
                                                            <v-btn type="submit" height="30px" dark block color="light-blue darken-2" :loading="loadingForSave">Save</v-btn>
                                                        </v-col>
                                                        <v-col cols="4" class="pl-2 py-1">
                                                            <v-btn type="reset" @click="resetForm" height="30px" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                        
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import swal from 'sweetalert'
export default {
    name: 'Sale',
    data: ()=> ({
        type: 'Retail',
        sale: {
            id: null,
            invoice: '',
            date: new Date().toISOString().substr(0, 10),
            customer_id: null,
            employee_id: null,
            po_no: '',
            note: '',
            po_date: new Date().toISOString().substr(0, 10),
            sub_total: 0,
            vat: 0,
            discount: 0,
            transport: 0,
            unload: 0,
            total: 0,
            paid: 0,
            due: 0,
        },
        vatPercent: 0.0,
        disPercent: 0.0,
        customer: {
            id: null,
            name: '',
            phone: '',
            address: '',
            type: '',
            display_text: 'Select Customer'
        },
        employee: null,
        product: {
            id: '',
            code: '',
            display_text: 'Select product',
            name: '',
            sale_rate: '',
            quantity: '',
            total: '',
            unit: {}
        },
        cart: [],
        editCartIndex: null,
        modal: false,
        loadingForSave: false,
        stock: '',
        carton: 0,
        rate: 0,
        unit_rate: 0,
        pice: 0,
        freeQty: 0,
        qty: 0,
    }),

    watch: {
        customer(customer) {
            if(customer == undefined) return;
            this.sale.customer_id = customer.id;
            this.selectRate();
            this.customer = customer;
            this.calculateTotal();
        },
        async product(product) {
            if(product == undefined) return;
            this.product = product;
            this.product.sale_rate = this.type == 'Wholesale' ? product.wholesale_rate : product.sale_rate;
            if(product.id != '') {
                // this.$refs.quantity.ocus();
                await this.$store.dispatch('product/getCurrentStock', {productId: product.id})
                // this.stock = await this.$store.getters['product/stock'][0].current_quantity;
                let some = await this.$store.getters['product/stock'][0];
                this.selectRate();
                if (some && some.current_quantity > 0) {
                    this.stock = some.current_quantity;
                } else { 
                    swal({
                        title: 'Product stock out',
                        icon: 'error',
                        buttons: true
                    }).then(()=> {
                        this.stock = 0;
                    });
                    return;
                }
                // flat_rate
                // cm_rate
                // wholesale_rate
                // sale_rate
                let prepare_card_item = {
                    id: product.id,
                    product: product,
                    current_quantity: this.stock,
                    code: product.code,
                    name: product.name,
                    purchase_rate: product.purchase_rate,
                    sale_rate: this.rate,
                    quantity: 0,
                    carton_quantity: product.carton_quantity,
                    total: 0,
                    carton: this.carton,
                    freeQty: this.freeQty,
                    pice: this.pice,
                    item_type: product.item_type,
                    display_text: product.display_text
                }
                
                let cartIndex = this.cart.findIndex(p => p.id == product.id)
                if(cartIndex > -1) {
                    this.$store.dispatch("snackbar/error", "Product already existed in cart");
                    return;
                } else {
                    this.cart.unshift(prepare_card_item);
                    this.product = {
                        id: '',
                        code: '',
                        display_text: 'Select product',
                        name: '',
                        sale_rate: '',
                        quantity: '',
                        total: '',
                        unit: {}
                    };
                }

                
                // console.log(some);
            }
        },
        employee(employee) {
            if(employee == undefined) return;
            this.sale.employee_id = employee.id;
        },
        cart: {
            handler(newValue) {
                console.log('cart');
                this.calculateTotal();
            },
            deep: true
            // this.calculateTotal(cart);
        },
    },

    computed: {
        customers() {
            let customer = this.$store.getters['customer/customers'];
            return customer;
        }, 
    },

    async created() {
        this.sale.invoice = await this.$store.dispatch('sale/generateSaleCode');

        if (this.$route.params.id != undefined) {
            await this.$store.dispatch('sale/getSales', {id: this.$route.params.id});
            await this.getSales();
        }
    },

    methods: {
        getRate(product) {
            let rate = null;
            if (this.customer && product) {
                switch (this.customer.type) {
                    case 'Depo': rate = product.sale_rate; break; 
                    case 'Diler': rate = product.wholesale_rate; break;
                    case 'Flat': rate = product.flat_rate; break;
                    case 'CM': rate = product.cm_rate; break;
                    default: break;
                }
            }
            return rate;
        },
        getTotalPrice(cart) {
            let cart_qty = +cart.carton_quantity * +cart.carton + +cart.pice + +cart.freeQty;
            if (cart_qty > cart.current_quantity) {
                cart.carton = 0;
                cart.pice = 0;
                cart.freeQty = 0;
                this.$store.dispatch('snackbar/error', 'Stock not available');
            }
            let freeQty = Number(cart.freeQty) ? Number(cart.freeQty) : 0;
            if (cart.product.item_type == 1) {
                cart.sale_rate = this.getRate(cart.product);
                let unitPrice = Number(this.getRate(cart.product) / cart.product.carton_quantity);
                let quantity = ((Number(cart.carton) * Number(cart.product.carton_quantity)) + Number(cart.pice));
                cart.quantity = Number(quantity) + freeQty;
                cart.total = Number(unitPrice * quantity);
                return parseFloat(Number(unitPrice * quantity).toFixed(2));
            } else {
                cart.quantity = parseFloat(cart.pice) + freeQty;
                cart.total = cart.pice ? this.getRate(cart.product) * cart.pice : 0; 
                let valueAbleQty = cart.quantity - cart.freeQty;
                return valueAbleQty ? this.getRate(cart.product) * valueAbleQty : 0;
            }
        },
        selectRate() {
            if (this.customer && this.product) {
                switch (this.customer.type) {
                    case 'Depo':
                        this.rate = this.product.sale_rate;
                        break;
                    case 'Diler':
                        this.rate = this.product.wholesale_rate;
                        break;
                    case 'Flat':
                        this.rate = this.product.flat_rate;
                        break;
                    case 'CM':
                        this.rate = this.product.cm_rate;
                        break;

                    default:
                        break;
                }
            }
        },

        deleteCart(ind) {
            this.cart.splice(ind, 1);
            this.calculateTotal();
        },

        calculateTotal() {
            let sub_total = 0;
            this.cart.map((item) => {
                sub_total += this.getTotalPrice(item);
            }, 0)

            this.sale.sub_total = parseFloat(Number(sub_total).toFixed(2));
            if(event.target.id == "vat") {
                this.sale.vat = ((+this.sale.sub_total * +this.vatPercent) / 100);
            } else {
                this.vatPercent = ((+this.sale.vat / +this.sale.sub_total) * 100);
            }

            if(event.target.id == "discount") {
                this.sale.discount = ((+this.sale.sub_total * +this.disPercent) / 100);
            } else {
                this.disPercent = parseFloat(((+this.sale.discount / +this.sale.sub_total) * 100).toFixed(2));
            }

            this.sale.total = parseFloat(((+this.sale.sub_total + +this.sale.vat) - (+this.sale.discount + +this.sale.transport + +this.sale.unload)).toFixed(2));

            if(this.customer.type == 'G') {
                this.sale.paid = this.sale.total;
            } else {
                this.sale.due = parseFloat(Number(+this.sale.total - +this.sale.paid).toFixed(2));
            }
            
        },

        clearProduct() {
            this.product = {
                id: '',
                display_text: '',
                name: '',
                sale_rate: '',
                sale_rate: '',
                quantity: '',
                total: '',
                unit: {}
            }
            this.editCartIndex = null;
            this.stock = '';
            this.carton = 0;
            this.pice = 0;
            this.unit_rate = 0;
            // this.freeQty = 0;
            this.$refs.productForm.resetValidation()
        },

        validateSale() {
            let isValid = true;
            
            this.$refs.saleForm.validate();

            this.$refs.saleForm.inputs.forEach(input => {
                if(input.hasError) isValid = false;
            })

            if(!isValid) return;

            if(this.customer.type == '' && this.sale.customer_id == null) {
                this.$store.dispatch('snackbar/error', 'Select a customer');
                isValid = false; 
                return isValid;
            }

            if(this.cart.length == 0) {
                this.$store.dispatch('snackbar/error', 'Cart is empty');
                isValid = false; 
                return isValid;
            }

              if(this.sale.employee_id == null) {
                this.$store.dispatch('snackbar/error', 'Select an employee');
                isValid = false; 
                return isValid;
            }

            return isValid;
        },

        async saveSale() {
            if(!this.validateSale()) {
                return;
            }
     
            let data = {
                sale: this.sale,
                cart: this.cart,
                customer: this.customer
            }
            this.loadingForSave = true;

            let res = await this.$store.dispatch('sale/saveSale', data);

            if(res) {
                this.resetForm();
                if(res.isSuccess) {
                    this.resetForm();
                    swal({
                        title: 'Sale success! Do you want to show invoice ?',
                        icon: 'success',
                        buttons: true
                    }).then(response => {
                        if (response) this.$router.push(`/sale-invoice/${res.id}`)
                    })
                }
            }

            this.loadingForSave = false
        },

        async resetForm() {
            this.cart = [];
            Object.keys(this.sale).map(k => this.sale[k] = '');
            this.$refs.saleForm.resetValidation();
            this.sale.id = null;
            this.customer = null;
            this.sale.date = new Date().toISOString().substr(0, 10);
            this.sale.po_date = new Date().toISOString().substr(0, 10);
            this.sale.invoice = await this.$store.dispatch('sale/generateSaleCode');
        },

        async getSales() {
            let sale = await this.$store.getters['sale/sales'][0]
            this.sale.id = sale.id;
            this.sale.invoice = sale.invoice;
            this.sale.date = sale.date;
            this.sale.customer_id = sale.customer_id;
            this.sale.po_no = sale.po_no;
            this.sale.po_date = sale.po_date;
            this.sale.po_date = sale.po_date;
            this.sale.sub_total = sale.sub_total;
            this.sale.vat = sale.vat;
            this.sale.note = sale.note;
            this.sale.discount = sale.discount;
            this.sale.transport = sale.transport;
            this.sale.unload = sale.unload;
            this.sale.total = sale.total;
            this.sale.paid = sale.paid;
            this.sale.due = sale.due;

            this.customer = sale?.customer;
            this.customer.display_text = `${sale?.customer.code} - ${sale?.customer.name == null ? '' : sale?.customer.name} - ${sale?.customer.phone == null ? '' : sale?.customer.phone}`;

            this.employee = sale?.employee;
            this.employee.display_text = `${sale?.employee.emp_id} - ${sale?.employee.name}`;

            sale?.sale_details.forEach(async (item) => {
                await this.$store.dispatch('product/getCurrentStock', {productId: item.product.id})
                let some = await this.$store.getters['product/stock'][0];
                this.cart.unshift({
                    id: item.product.id,
                    code: item.product.code,
                    name: item.product.name,
                    product: item.product,
                    purchase_rate: item.purchase_rate,
                    current_quantity: some.current_quantity,
                    carton_quantity: item.carton_quantity,
                    sale_rate: item.sale_rate,
                    carton: item.carton,
                    pice: item.pice,
                    item_type: item.item_type,
                    freeQty: item.free_qty,
                    quantity: item.quantity,
                    total: item.total,
                    display_text: `${item.product.code} - ${item.product.name}`
                })
            });
        }
    }

}
</script>

<style lang="scss" scoped>
    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
        // height: 18px !important;
        border: 1px solid #ccc;
    }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        color: #000 !important;
        font-weight: 600 !important;
        border: 1px solid #ccc !important;
        text-align: center;
        // height: 25px !important;
        letter-spacing: 0.7px;
    }

</style>
